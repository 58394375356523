<template>
    <div  v-if="resultsData" class="yellow-holder">
        <div class="container table-responsive">
            <table class="table table-advance">
                <thead>
                    <tr>
                        <th>Rangsor</th>
                        <th>Csapatnév</th>
                        <th>Iskola</th>
                        <th>Selejtezős régió</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="result in resultsData" :key="result.teamName"> 
                        <td>{{result.sort}}</td>
                        <td>{{result.teamName}}</td>
                        <td>{{result.university}}</td>
                        <td>{{result.league}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Participants',
  props:['globalData','pageId'],
  data(){
    const language = this.globalData.init.language
    const contents = this.globalData.contents[language][this.pageId]
    const headData = contents.head

    this.globalData.init.head.title = headData.title
    this.globalData.init.head.keywords = headData.keywords
    this.globalData.init.head.description = headData.description
    
    this.fetchData()

    return{
        resultsData:null
    }
  },
  watch:{
    language(){
      this.contents = this.globalData.contents[this.language][this.pageId]
      this.globalData.init.head.title = this.contents.head.title
      this.globalData.init.head.keywords = this.contents.head.keywords
      this.globalData.init.head.description = this.contents.head.description
    }
  },
  methods:{
      fetchData() {
      this.globalData.loader.status = true;    
      this.$axios.get('/api/v1/participants')
          .then((response) => {
              this.resultsData = response.data.data
          })
          .catch(error => {
              console.log(error.message)
              console.error('There was an error!', error)
          })
          .then(() => {
                this.globalData.loader.status = false;
          });
    }
  }
}
</script>
<style scoped>
.yellow-holder{
    background-color: #FCB925;
    padding-top: 2rem;
    padding-bottom:1.14rem;
    margin-bottom: 4.55rem;
}
thead{
    background: rgba(255, 254, 254, 0.2);
    border-bottom: 2px solid rgba(255, 254, 254, 0.2);
}
th{
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: .82rem;
    line-height: 2.18rem;
    color: #000000;
    text-align: center;
    vertical-align: middle;
}
tbody tr:first-child td{
    padding-top:1.82rem;
}
table th:nth-child(2){
    text-align: start;
}
table th:nth-child(3){
    text-align: start;
    padding-left: 2.32rem;
}
table td:nth-child(1),table td:nth-child(4),table td:nth-child(5){
    text-align: center;
}
table td:nth-child(1),table td:nth-child(2),table td:nth-child(5){
    color:#FFFEFE;
    font-weight: bold;
}
.table>:not(caption)>*>*{
    border: none;
}
@media (max-width: 991px){
    th{
        line-height: 1.18rem;
    }
}
</style>
